import React from "react";
import { Button } from "semantic-ui-react";
import { ReactComponent as Logo } from "./static/corelogic.svg";

import "./Launch.css";
import clientConfig from "./client.json";
const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  description?: string = "description";
  title?: string = "title";
}

interface LaunchProps {
  Launch: () => void;
}

export const LaunchView: React.FC<LaunchProps> = (props: LaunchProps) => {
  return (
    <div id="launchContainer">
      <div className="logo-holder">
        <Logo width="192" />
      </div>
      <h1 className="welcome-message">Welcome to the</h1>
      <h1 className="welcome-message">CoreLogic FIND Virtual Experience</h1>
      <div className="button-holder">
        <Button
          id="launch"
          size="massive"
          onClick={() => props.Launch()}
        >ENTER</Button>
      </div>
    </div>
  );
};

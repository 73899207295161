/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.
 
 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */

import {
  LaunchStatusEvent,
  LaunchStatusType,
  ModelDefinition,
  PlatformNext,
  UndefinedModelDefinition,
  InputEmitter,
  DefaultStreamerOptions,
  StreamerStatus,
} from "@pureweb/platform-sdk";

import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  LaunchRequestOptions,
  VideoStream,
  System,
} from "@pureweb/platform-sdk-react";

import * as qs from "query-string";
import React, { useEffect, useState, useRef } from "react";
import Fullscreen from "react-full-screen";
import { Button, Icon } from "semantic-ui-react";
import useAsyncEffect from "use-async-effect";

import "./App.css";
import clientConfig from "./client.json";

import { LaunchView } from "./Launch";
import logger from "./Log";
import Guest from "./Guest";

// access chatweeManager in window object
declare global {
  interface Window {
    chatweeManager: any;
  }
}

const client: ClientJson = clientConfig as ClientJson;

class ClientJson {
  environmentId?: string;
  launchType?: string;
  projectId?: string;
  modelId?: string;
  version?: string;
  endpoint?: string;
  usePointerLock?: boolean;
  pointerLockRelease?: boolean;
}

class ClientOptions {
  // Overridable connection options
  LaunchType?: string;

  // Launch queue configuration
  ProjectId?: string;
  ModelId?: string;
  Version?: string;
  EnvironmentId?: string;
  Endpoint?: string;

  // Overridable streamer options
  ForceRelay = false;
  UseNativeTouchEvents = false;
  UsePointerLock?: boolean;
  PointerLockRelease?: boolean;

  isValid(): boolean {
    if (!this.ProjectId) {
      return false;
    }
    if (!this.ModelId) {
      return false;
    }
    return true;
  }
}

interface LoadingProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
}

const LoadingView: React.FC<LoadingProps> = (props: LoadingProps) => {
  if (
    props.StreamerStatus === StreamerStatus.Connected ||
    props.StreamerStatus === StreamerStatus.Completed
  ) {
    return <div />;
  }

  let content;

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>
          Your browser does not support the necessary WebRTC capabilities.
        </h3>
      </div>
    );
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    );
  } else {
    content = (
      <div>
        <div>
          <svg
            className="logo"
            xmlns="http://www.w3.org/2000/svg"
            width={80.084}
            height={61.296}
            viewBox="0 0 80.084 61.296"
          >
            <path
              className="cls-2 svg-elem-1"
              fill="#e10600"
              d="M19.627 10.872l18.372 10.61 18.376-10.61L37.999.265z"
            />
            <path
              className="cls-3 svg-elem-2"
              fill="#5a5a5a"
              d="M38 42.698V21.482l-18.373 10.61z"
            />
            <path
              className="cls-4 svg-elem-3"
              fill="#828282"
              d="M38 21.482v21.216l18.375-10.607z"
            />
            <path
              className="cls-4 svg-elem-4"
              fill="#828282"
              d="M19.627 10.872v21.22l18.372-10.61z"
            />
            <path
              d="M.265 53.295v-.03c0-3.018 2.26-5.49 5.474-5.49a5.532 5.532 0 014.156 1.668l-1.198 1.365c-.85-.794-1.76-1.323-2.971-1.323-2.02 0-3.506 1.67-3.506 3.747v.031c0 2.077 1.474 3.763 3.506 3.763 1.304 0 2.137-.516 3.048-1.366l1.198 1.212c-1.108 1.154-2.32 1.881-4.307 1.881-3.096.003-5.4-2.407-5.4-5.458zm10.784 1.323v-.03a4.27 4.27 0 018.538-.03v.03a4.186 4.186 0 01-4.291 4.168 4.14 4.14 0 01-4.247-4.14zm6.705 0v-.03c0-1.412-1.02-2.579-2.458-2.579-1.44 0-2.413 1.154-2.413 2.548v.032c0 1.394 1.016 2.564 2.442 2.564 1.487-.003 2.429-1.157 2.429-2.538zm3.548-4.048h1.836v1.804c.5-1.198 1.423-2.018 2.836-1.958v1.937h-.108c-1.606 0-2.728 1.058-2.728 3.175v3.048h-1.836zm5.929 4.032v-.029c0-2.291 1.624-4.17 3.913-4.17 2.548 0 3.84 2 3.84 4.308 0 .166-.017.333-.032.513H29.07c.196 1.304 1.122 2.034 2.304 2.034a2.91 2.91 0 002.17-.955l1.077.955a4.05 4.05 0 01-3.276 1.5c-2.323-.002-4.114-1.685-4.114-4.159zm5.932-.59c-.122-1.183-.82-2.117-2.032-2.117-1.125 0-1.913.866-2.08 2.117z"
              className="cls-2 svg-elem-5"
              fill="#e10600"
            />
            <path
              d="M37.044 47.956h1.866v8.919h5.582v1.698h-7.448zm8.025 6.66v-.033a4.27 4.27 0 018.54-.029v.03a4.188 4.188 0 01-4.293 4.172 4.14 4.14 0 01-4.247-4.14zm6.705 0v-.033c0-1.41-1.016-2.577-2.458-2.577-1.442 0-2.41 1.151-2.41 2.548v.03c0 1.396 1.015 2.563 2.441 2.563 1.487 0 2.427-1.153 2.427-2.532zm3.41 5.46l.683-1.38a5.265 5.265 0 002.852.848c1.638 0 2.532-.849 2.532-2.458v-.621c-.667.865-1.5 1.47-2.82 1.47-1.882 0-3.641-1.394-3.641-3.746v-.029c0-2.381 1.775-3.762 3.64-3.762a3.458 3.458 0 012.808 1.365v-1.198h1.833v6.37c0 1.35-.346 2.35-1.016 3.02-.727.727-1.852 1.076-3.291 1.076a6.9 6.9 0 01-3.58-.955zm6.086-5.9v-.032c0-1.304-1.077-2.198-2.35-2.198-1.273 0-2.275.878-2.275 2.198v.032a2.183 2.183 0 002.275 2.199c1.27 0 2.347-.897 2.347-2.199zm3.987-6.585h1.971v1.746h-1.971zm.074 2.974h1.839v8.008h-1.836zm3.717 4.05v-.032a4.133 4.133 0 014.17-4.185 4.043 4.043 0 013.202 1.38l-1.154 1.229c-.56-.59-1.154-1-2.064-1-1.323 0-2.32 1.15-2.32 2.547v.03c0 1.426.987 2.563 2.397 2.563.865 0 1.503-.394 2.08-.987l1.106 1.093a4.199 4.199 0 01-7.409-2.646z"
              className="cls-4 svg-elem-6"
              fill="#828282"
            />
            <path
              d="M77.364 50.385a1.228 1.228 0 112.455 0 1.228 1.228 0 11-2.455 0zm2.313 0a1.077 1.077 0 00-1.085-1.093 1.09 1.09 0 00-1.088 1.098 1.087 1.087 0 102.173 0zm-1.564-.649h.563c.265 0 .48.135.48.395a.378.378 0 01-.292.383l.334.471h-.289l-.296-.428h-.265v.428h-.238zm.542.622c.162 0 .265-.085.265-.204 0-.119-.093-.206-.265-.206h-.304v.41z"
              className="cls-4 svg-elem-7"
              fill="#828282"
            />
          </svg>
        </div>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width={252}
            height={69}
            viewBox="0 0 128 35"
          >
            <g>
              <circle fill="#e92400" cx={17.5} cy={17.5} r={7.5} />
              <animate
                attributeName="opacity"
                dur="1800ms"
                begin="1.5s"
                repeatCount="indefinite"
                keyTimes="0;0.167;0.5;0.668;1"
                values="0.3;1;1;0.3;0.3"
              />
            </g>
            <g>
              <circle fill="#e92400" cx={110.5} cy={17.5} r={7.5} />
              <animate
                attributeName="opacity"
                dur="1800ms"
                begin="1.5s"
                repeatCount="indefinite"
                keyTimes="0;0.334;0.5;0.835;1"
                values="0.3;0.3;1;1;0.3"
              />
            </g>
            <g>
              <circle fill="#e92400" cx={64} cy={17.5} r={7.5} />
              <animate
                attributeName="opacity"
                dur="1800ms"
                begin="1.5s"
                repeatCount="indefinite"
                keyTimes="0;0.167;0.334;0.668;0.835;1"
                values="0.3;0.3;1;1;0.3;0.3"
              />
            </g>
          </svg>
        </div>
        <h3>Please wait, we are preparing your Virtual Experience</h3>
      </div>
    );
  }
  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <div style={{ textAlign: "center" }}>{content}</div>
    </div>
  );
};

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio();
audio.autoplay = true;
audio.volume = 0.25;

// Parse query parameters
const query = qs.parse(window.location.search);
const clientOptions: ClientOptions = new ClientOptions();
clientOptions.LaunchType = (query["launchType"] as string) ?? client.launchType;
clientOptions.Endpoint = (query["endpoint"] as string) ?? client.endpoint;
clientOptions.ProjectId = (query["projectId"] as string) ?? client.projectId;
clientOptions.ModelId = (query["modelId"] as string) ?? client.modelId;
clientOptions.Version = (query["version"] as string) ?? client.version;
clientOptions.EnvironmentId =
  (query["environmentId"] as string) ?? client.environmentId;
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query["usePointerLock"] === undefined
    ? client.usePointerLock
    : query["usePointerLock"] === "true") ?? true;
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query["pointerLockRelease"] === undefined
    ? client.pointerLockRelease
    : query["pointerLockRelease"] === "true") ?? true;

clientOptions.ForceRelay = query["forceRelay"] !== undefined ?? false;
clientOptions.UseNativeTouchEvents =
  query["useNativeTouchEvents"] !== undefined ?? false;
const bmid = query.bmid as string; // BigMarker User ID

// Initialize platform reference
const platform = new PlatformNext();
platform.initialize({
  endpoint: clientOptions.Endpoint || "https://api.pureweb.io",
});

interface ViewProps {
  LaunchRequestStatus: LaunchStatusEvent;
  StreamerStatus: StreamerStatus;
  VideoStream: MediaStream;
  InputEmitter: InputEmitter;
  UseNativeTouchEvents: boolean;
  UsePointerLock: boolean;
  PointerLockRelease: boolean;
}

const EmbeddedView: React.FC<ViewProps> = (props: ViewProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isFull, setIsFull] = useState(false);

  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  const isIPhone =
    System.Browser().os === "iOS" &&
    !window.navigator.userAgent.includes("iPad");

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div style={{ height: "100%" }}>
      <Fullscreen enabled={isFull} onChange={(isFull) => setIsFull(isFull)}>
        <IdleTimeout
          Status={props.StreamerStatus}
          WarningThreshold={1200}
          ExitThreshold={120}
          WarningCallback={() => setIsFull(false)}
          ExitCallback={() => window.location.reload()} // TODO: How to 'close' a contribution?
        />

        <LoadingView
          LaunchRequestStatus={props.LaunchRequestStatus}
          StreamerStatus={props.StreamerStatus}
        />
        <VideoStream
          VideoRef={videoRef}
          Emitter={props.InputEmitter}
          Stream={props.VideoStream}
          UseNativeTouchEvents={props.UseNativeTouchEvents}
          UsePointerLock={props.UsePointerLock}
          PointerLockRelease={props.PointerLockRelease}
        />

        {/* <Button
          onClick={() => {
            setIsFull(true);
          }}
          style={{ position: "absolute", top: 10, right: 10 }}
          className={
            isIPhone ||
            isFull ||
            props.StreamerStatus !== StreamerStatus.Connected
              ? "hidden"
              : ""
          }
        >
          <Icon name="expand" />
        </Button> */}
      </Fullscreen>
    </div>
  );
};

const App: React.FC = () => {
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] =
    useState(false);
  const [modelDefinition, setModelDefinition] = useState(
    new UndefinedModelDefinition()
  );
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const streamerOptions = DefaultStreamerOptions;

  useEffect(() => {
    window.onload = () => {
      if (window.chatweeManager) {
        // remove chatwee widget on mount
        window.chatweeManager.Dispose();
      }
    };
  }, []);

  useAsyncEffect(async () => {
    if (clientOptions.ProjectId) {
      logger.info("Initializing available models: " + clientOptions.ProjectId);
      try {
        await platform.useAnonymousCredentials(
          clientOptions.ProjectId,
          clientOptions.EnvironmentId
        );
        await platform.connect();
        logger.info("Agent Connected: " + platform.agent.id);
        streamerOptions.iceServers = platform.agent.serviceCredentials
          .iceServers as RTCIceServer[];
        streamerOptions.forceRelay = clientOptions.ForceRelay;
        const models = await platform.getModels();
        setAvailableModels(models);
        logger.debug("Available models", models);
      } catch (err) {
        logger.error(err);
      }
    }
  }, [clientOptions]);

  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (
        model: ModelDefinition
      ): boolean {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (
            clientOptions.Version &&
            clientOptions.Version === model.version
          ) {
            return true;
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true;
          }
        }
        return false;
      });
      if (selectedModels?.length) {
        setModelDefinition(selectedModels[0]);
      } else {
        setModelDefinitionUnavailable(true);
      }
    }
  }, [availableModels]);

  const launchRequestOptions: LaunchRequestOptions = {
    regionOverride: query["regionOverride"] as string,
  };
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(
    platform,
    modelDefinition,
    launchRequestOptions
  );
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] =
    useStreamer(platform, launchRequest, streamerOptions);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }
  }, [streamerStatus]);

  useEffect(() => {
    const registerUser = async () => {
      // look for guest info on bigmarker
      let guest = await Guest.getBigMarkerGuest(bmid);

      // if not found, assign anonymous guest info
      if (!guest) {
        guest = Guest.getAnonymousGuest();
      }

      // register guest
      guest.register().catch((error) => console.error(error));

      // send guest info to game
      emitter.EmitUIInteraction({
        email: guest.email,
        firstName: guest.firstName,
        lastName: guest.lastName,
        company: guest.company,
        jobTitle: guest.jobTitle,
      });
    };

    if (streamerStatus === StreamerStatus.Connected && emitter.m_dc) {
      // HACK: wait until game BeginEvents are finish
      setTimeout(() => {
        registerUser();
      }, 1000);
    }
  }, [streamerStatus, emitter]);

  if (audioStream) {
    audio.srcObject = audioStream;
  }

  const launch = async () => {
    setLoading(true);
    audio.load();

    if (clientOptions.LaunchType !== "local") {
      await queueLaunchRequest();
    }
  };

  // Log status messages
  useEffect(() => {
    logger.info("Status", status, streamerStatus);
  }, [status, streamerStatus]);

  // Subscribe to game messages
  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        logger.info("Message: " + value);
      },
      (err) => {
        logger.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject]);

  // useEffect(() => {
  //   if (modelDefinition instanceof UndefinedModelDefinition) {
  //     return;
  //   }
  //   try {
  //     //cancel any existing
  //     if (launchRequest) launchRequest.cancel();
  //     launch();
  //   } catch (err) {
  //     //communicate to the end user that this failed ...
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [modelDefinition, platform]);

  // Notify user of missing or errors in configuration
  if (!clientOptions.isValid()) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p>
          Your client has one or more configuration errors. Please consult the{" "}
          <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client">
            {" "}
            README{" "}
          </a>{" "}
          for details on how to configure the client template.
        </p>
      </div>
    );
  }

  if (modelDefinitionUnavailable) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span>The model that you have requested does not exist</span>:
      </div>
    );
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Disconnected from stream</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2>Failure during stream</h2>
        <h2>Please refresh to request a new session</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Streamer contribution withdrawn</h2>
      </div>
    );
  }

  if (loading) {
    return (
      <EmbeddedView
        VideoStream={videoStream}
        StreamerStatus={streamerStatus as StreamerStatus}
        LaunchRequestStatus={status}
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents}
        UsePointerLock={clientOptions.UsePointerLock!}
        PointerLockRelease={clientOptions.PointerLockRelease!}
      />
    );
  } else if (clientOptions.LaunchType !== "local" && !availableModels) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>Initializing...</h2>
      </div>
    );
  } else if (clientOptions.LaunchType !== "local" && !availableModels?.length) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2>No models are currently available in this environment.</h2>
      </div>
    );
  } else {
    return <LaunchView Launch={launch} />;
  }
};

const AppWrapper: React.FC = () => {
  return System.IsBrowserSupported() ? (
    <App />
  ) : (
    <div className="ui red segment center aligned basic">
      <h2 className="header">Your browser is currently unsupported</h2>
    </div>
  );
};

export default AppWrapper;

import { GraphQLClient } from "graphql-request";
import axios from "axios";
import apiConfig from "./api.json";

class ApiJson {
  rootUrl?: string;
}

const api: ApiJson = apiConfig as ApiJson;

interface TBigMarkerCustomField {
  Company: string;
  Title: string;
}

interface TBigMarkerRegistrant {
  bmid: string;
  email: string;
  first_name: string;
  last_name: string;
  company: string;
  custom_fields: Array<TBigMarkerCustomField>;
}

interface TGuest {
  Guest: {
    email: string;
    firstName: string;
    lastName: string;
    company: string;
    jobTitle: string;
  };
}

export default class Guest {
  bmid?: string;
  email!: string;
  firstName!: string;
  lastName!: string;
  company!: string;
  jobTitle!: string;

  private chatweeUserId: string;
  private chatweeSessionId: string;
  private chatweeChatId: string;

  private graphQLClient: GraphQLClient;
  private readonly addGuestMutation: string = `mutation (
    $email: String!, 
    $firstName: String!, 
    $lastName: String!, 
    $company: String!, 
    $jobTitle: String!
  ) {
    addGuest(
      email: $email, 
      firstName: $firstName, 
      lastName: $lastName, 
      company: $company, 
      jobTitle: $jobTitle
    ) {
      email,
      firstName,
      lastName,
      company,
      jobTitle
    }
  }`;

  constructor(
    email: string,
    firstName: string,
    lastName: string,
    company: string,
    jobTitle: string
  ) {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.company = company || "";
    this.jobTitle = jobTitle || "";

    this.graphQLClient = new GraphQLClient(`${api.rootUrl}/graphql`, {
      mode: "cors",
    });

    this.chatweeUserId = "";
    this.chatweeSessionId = "";
    this.chatweeChatId = "";

    window.onbeforeunload = (_e: any) => {
      this.chatweeLogout();
    };
  }

  register = async () => {
    // register to ChatWee
    this.chatweeLogin(`${this.firstName} ${this.lastName} (${this.email})`);

    // register to Records API
    try {
      await this.graphQLClient.request<TGuest>(this.addGuestMutation, this);
    } catch (ex) {
      console.error(ex);
    }
  };

  chatweeLogin = async (login: string) => {
    const resp = await axios.post(`${api.rootUrl}/chatwee/login`, {
      login,
    });

    const data = resp.data;
    document.cookie = `chatwee-SID-${data.chatId}=${data.sid}`;

    this.chatweeUserId = data.userId;
    this.chatweeSessionId = data.sid;
    this.chatweeChatId = data.chatId;

    window.chatweeManager.Run();
    window.chatweeManager.Minimize();

    return data;
  };

  chatweeLogout = () => {
    axios.post(`${api.rootUrl}/chatwee/logout`, {
      userId: this.chatweeUserId,
      sid: this.chatweeSessionId,
    });

    document.cookie = `chatwee-SID-${this.chatweeChatId}=`;
  };

  static getAnonymousGuest: () => Guest = function () {
    const uid = Date.now();

    const guest = new Guest(
      `guest${uid}@polygonlabs.us`,
      "guest",
      `${uid}`,
      "Polygon Labs",
      "Guest"
    );

    return guest;
  };

  static getBigMarkerGuest: (bmid: string) => Promise<Guest | null> =
    async function (bmid: string): Promise<Guest | null> {
      const resp = await axios.get(`${api.rootUrl}/bigmarker/registrants`);
      const registrant = resp?.data?.registrations?.find(
        (registrant: TBigMarkerRegistrant) => registrant.bmid === bmid
      );

      if (registrant) {
        return new Guest(
          registrant.email,
          registrant.first_name,
          registrant.last_name,
          registrant?.custom_fields[0]?.Company || "",
          registrant?.custom_fields[0]?.Title || ""
        );
      }

      return null;
    };
}
